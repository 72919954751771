import { useContext, useEffect, useState } from "react";
import HomeSideNavbar from "../../components/homeSideNavbar/HomeSideNavbar";
import SubscriberHeader from "../../components/subscriberHeader/SubscriberHeader";
import "./Dashboard.css";
import "./Dashboard.css";
import { GoChevronDown } from "react-icons/go";
import RefreshLoaderSVG from "../../asset/svg/RefreshLoaderSVG";
import CircleLoaderSVG from "../../asset/svg/CircleLoaderSVG";
import { MyContext } from "../../ContextAPIStore/CreateContext";
import { DashboardStatsGetAPI } from "../../services/servicesAPI";
import { current } from "@reduxjs/toolkit";
import { convertNumberIntoUSFromat } from "../../utils/Utils";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const [selectedOption, setSelectedOption] = useState({
    Option: "Current",
    payload: "current",
  });

  const [isOpen, setIsOpen] = useState(false);
  const [isRotate, setIsRotate] = useState(false);
  const [dashData, setDashData] = useState([]);
  const [option, setOption] = useState(["Current"]);
  const navigate = useNavigate();

  let date = new Date();
  console.log("date", date);
  console.log("SelectedOPtion", selectedOption);

  const redirectToContactPage = (key) => {
    if(key) {
      navigate(`/contacts?type=dashboard&dashKey=${key}`);
    } else {
      navigate(`/contacts`);
    }
  }

  const getLastFourMonths = () => {
    const months = ["Current"];
    const currentDate = new Date();
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    for (let i = 0; i < 4; i++) {
      const monthIndex = (currentDate.getMonth() - i + 12) % 12; // Ensure monthIndex is always positive
      const year =
        currentDate.getFullYear() - (currentDate.getMonth() - i < 0 ? 1 : 0);
      months.push(`${monthNames[monthIndex]} ${year}`);
    }

    return months;
  };

  useEffect(() => {
    let month = getLastFourMonths();
    month.splice(1, 1);
    setOption(month);
  }, []);

  console.log(option);

  const constConfigData = useContext(MyContext);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    let date = new Date(option);

    console.log("option", option);

    if (option == "Current") {
      setSelectedOption({ Option: "Current", payload: "current" });
      setIsOpen(false);
    } else {
      const date = new Date(option); // Create a Date object from the string
      const options = { month: "long" }; // Define options for formatting the month
      const month = new Intl.DateTimeFormat("en-US", options).format(date); // Format the date to get the month
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      function getYearMonth(option) {
        let res = option.split(" ");
        let monthName = res[0];
        let year = res[1];
        let monthIndex = monthNames.findIndex((e) => e === monthName);
        let month = (monthIndex + 1).toString().padStart(2, "0");
        return `${year}-${month}`;
      }
      let res = getYearMonth(option);
      console.log("optionElse=========>", res);
      setSelectedOption({ Option: month, payload: `${res}-01` });
      setIsOpen(false);
    }
  };
  const handleRefresh = () => {
    setSelectedOption({ Option: "Current", payload: "current" });
    DashboardStatsGetAPIFun(selectedOption?.payload);
    setIsRotate(true);
    setTimeout(() => {
      setIsRotate(false);
    }, 500);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      let target = event.target;
      let isInsideDropdown = target.closest(".dashboard-dropdown-dp");
      if (!isInsideDropdown) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const DashboardStatsGetAPIFun = async (params) => {
    let res = await DashboardStatsGetAPI(params);
    if (res.success) {
      console.log("Dashborad_Res", res);
      setDashData(res?.data?.data);
      setIsRotate(false);
    }
  };

  useEffect(() => {
    DashboardStatsGetAPIFun(selectedOption?.payload);
  }, [selectedOption]);

  useEffect(() => {
    DashboardStatsGetAPIFun(selectedOption?.payload);
  }, []);
  return (
    <div className="dashboard">
      <div style={{ height: "35px" }}></div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2
          style={{
            fontWeight: "600",
            fontSize: "2.4em",
            letterSpacing: "0.8px",
          }}
        >
          {constConfigData?.Dashboard}
        </h2>
        <div
          style={{
            width: "23%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            className="dashboard-dropdown-dp"
            style={{
              backgroundColor: "white",
              border: "1px solid #98989866",
              borderRadius: "4px",
              width: "79%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "0.6vw 0vw",
              position: "relative",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "90%",
              }}
              onClick={toggleDropdown}
            >
              <p
                style={{
                  fontSize: "1.1em",
                  fontWeight: "300",
                  color: "rgb(100,100,100)",
                }}
              >
                {" "}
                {selectedOption?.Option}
              </p>
              <GoChevronDown
                style={{
                  cursor: "pointer",
                  fontSize: "1.2em",
                  fontWeight: "400",
                }}
              />
            </div>
            {isOpen && (
              <div className="dropdown-options">
                {option.map((option, index) => (
                  <div
                    key={index}
                    className="dropdown-option"
                    onClick={() => handleOptionClick(option)}
                  >
                    {option}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div
            style={{
              backgroundColor: "#E5E7EB",
              borderRadius: "4px",
              width: "17%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={handleRefresh}
          >
            {!isRotate ? (
              <RefreshLoaderSVG />
            ) : (
              <div className="spinner">
                <CircleLoaderSVG />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="grid-container">
        <div id="contacts-heading">{constConfigData?.Contacts}</div>
        <div id="contacts">
          <div className="contacts-inner">
            
            <p className="text-one">{constConfigData?.Total_Contacts}</p>
            <p className="text-two" onClick={() => redirectToContactPage('totalContacts')}>{convertNumberIntoUSFromat(dashData?.totalContacts)}</p>
          </div>
        </div>
        <div className="grid-items">
          <div className="grid-item-container">
            <div>
              <p className="text-1">Has Email</p>
              <p className="text-2" onClick={() => redirectToContactPage('EmailExists')} >{convertNumberIntoUSFromat(dashData?.hasEmailCount)}</p>
            </div>{" "}
            <p className="text-3">
              {parseFloat(dashData?.hasEmailRate).toFixed(2)}%
            </p>
          </div>
        </div>
        <div className="grid-items">
          <div className="grid-item-container">
            <div>
              <p className="text-1">HBCU</p>
              <p className="text-2" onClick={() => redirectToContactPage('HBCU')}>{convertNumberIntoUSFromat(dashData?.totalHBCUCount)}</p>
            </div>{" "}
            <p className="text-3">
              {parseFloat(dashData?.HBCURate).toFixed(2)}%
            </p>
          </div>
        </div>
        <div className="grid-items">
          <div className="grid-item-container">
            <div>
              <p className="text-1">LinkedIn</p>
              <p className="text-2" onClick={() => redirectToContactPage('LinkedIn')}>{convertNumberIntoUSFromat(dashData?.linkedInCount)}</p>
            </div>{" "}
            <p className="text-3">
              {parseFloat(dashData?.linkedInRate).toFixed(2)}%
            </p>
          </div>
        </div>
        <div className="grid-items">
          <div className="grid-item-container">
            <div>
              <p className="text-1">Mailing Address</p>
              <p className="text-2" onClick={() => redirectToContactPage('MailingAddress')}>{convertNumberIntoUSFromat(dashData?.addressCount)}</p>
            </div>{" "}
            <p className="text-3">
              {parseFloat(dashData?.addressRate).toFixed(2)}%
            </p>
          </div>
        </div>
        <div className="grid-items">
          <div className="grid-item-container">
            <div>
              <p className="text-1">Opened Email</p>
              <p className="text-2" onClick={() => redirectToContactPage('OpenedEmail')}>{convertNumberIntoUSFromat(dashData?.openEmail)}</p>
            </div>{" "}
            <p className="text-3">
              {parseFloat(dashData?.openEmailRate).toFixed(2)}%
            </p>
          </div>
        </div>
        <div className="grid-items">
          <div className="grid-item-container">
            <div>
              <p className="text-1">Phone</p>
              <p className="text-2" onClick={() => redirectToContactPage('Phone')}>{convertNumberIntoUSFromat(dashData?.phone)}</p>
            </div>{" "}
            <p className="text-3">
              {parseFloat(dashData?.phoneRate).toFixed(2)}%
            </p>
          </div>
        </div>
        <div className="grid-items">
          <div className="grid-item-container">
            <div>
              <p className="text-1">No Emails</p>
              <p className="text-2" onClick={() => redirectToContactPage('NoEmails')}>{convertNumberIntoUSFromat(dashData?.noEmail)}</p>
            </div>{" "}
            <p className="text-3">
              {parseFloat(dashData?.noEmailRate).toFixed(2)}%
            </p>
          </div>
        </div>
        <div className="grid-items">
          <div className="grid-item-container">
            <div>
              <p className="text-1">Good Email</p>
              <p className="text-2" onClick={() => redirectToContactPage('GoodEmail')}>{convertNumberIntoUSFromat(dashData?.goodEmail)}</p>
            </div>{" "}
            <p className="text-3">
              {parseFloat(dashData?.goodEmailRate).toFixed(2)}%
            </p>
          </div>
        </div>
        <div className="grid-items">
          <div className="grid-item-container">
            <div>
              <p className="text-1">Sent Email</p>
              <p className="text-2" onClick={() => redirectToContactPage('SentEmail')}>{convertNumberIntoUSFromat(dashData?.sentEmail)}</p>
            </div>{" "}
            <p className="text-3">
              {parseFloat(dashData?.sentEmailRate).toFixed(2)}%
            </p>
          </div>
        </div>
        <div className="grid-items">
          <div className="grid-item-container">
            <div>
              <p className="text-1">Inactive</p>
              <p className="text-2" onClick={() => redirectToContactPage('Inactive')}>{convertNumberIntoUSFromat(dashData?.inActive)}</p>
            </div>{" "}
            <p className="text-3">
              {parseFloat(dashData?.inActive).toFixed(2)}%
            </p>
          </div>
        </div>
      </div>

      <div className="grid-container">
        <div id="contacts-heading">{constConfigData?.Email_Activity}</div>
        <div id="contacts">
          <div className="contacts-inner">
            <p className="text-one">{constConfigData?.Total_Sends}</p>
            <p className="text-two" onClick={() => redirectToContactPage('')}>{convertNumberIntoUSFromat(dashData?.totalSent)}</p>
          </div>
        </div>
        <div className="grid-items">
          <div className="grid-item-container2">
            <div className="grid-two-inner">
              <p className="text-1">Opens</p>
              <p className="text-2" onClick={() => redirectToContactPage('')}>{convertNumberIntoUSFromat(dashData?.totalOpenCount)}</p>
            </div>{" "}
          </div>
        </div>
        <div className="grid-items">
          <div className="grid-item-container2">
            <div className="grid-two-inner">
              <p className="text-1">Clicks</p>
              <p className="text-2" onClick={() => redirectToContactPage('')}>{convertNumberIntoUSFromat(dashData?.totalClickCount)}</p>
            </div>{" "}
          </div>
        </div>
        <div className="grid-items">
          <div className="grid-item-container2">
            <div className="grid-two-inner">
              <p className="text-1">Unsubscribes</p>
              <p className="text-2" onClick={() => redirectToContactPage('')}>{convertNumberIntoUSFromat(dashData?.totalUnsubscribeCount)}</p>
            </div>{" "}
          </div>
        </div>
        <div className="grid-items">
          <div className="grid-item-container2">
            <div className="grid-two-inner">
              <p className="text-1">Soft Bounces</p>
              <p className="text-2" onClick={() => redirectToContactPage('')}>{convertNumberIntoUSFromat(dashData?.totalSoftBounceCount)}</p>
            </div>{" "}
          </div>
        </div>
        <div className="grid-items">
          <div className="grid-item-container2">
            <div className="grid-two-inner">
              <p className="text-1">Spam Complaints</p>
              <p className="text-2" onClick={() => redirectToContactPage('')}>{convertNumberIntoUSFromat(dashData?.totalSpamCount)}</p>
            </div>{" "}
          </div>
        </div>
        <div className="grid-items">
          <div className="grid-item-container2">
            <div className="grid-two-inner">
              <p className="text-1">Suppressed</p>
              <p className="text-2" onClick={() => redirectToContactPage('')}>0</p>
            </div>{" "}
          </div>
        </div>
        <div className="grid-items">
          <div className="grid-item-container2">
            <div className="grid-two-inner">
              <p className="text-1">Hard Bounces</p>
              <p className="text-2" onClick={() => redirectToContactPage('')}>{convertNumberIntoUSFromat(dashData?.totalHardBounceCount)}</p>
            </div>{" "}
          </div>
        </div>
      </div>

      <div className="grid-container-3">
        <div id="contacts-heading3">{constConfigData?.System_Activity}</div>
        <div className="grid-items">
          <div className="grid-item-container">
            <div className="grid-two-inner">
              <p className="text-1">New Contacts</p>
              <p className="text-2" onClick={() => redirectToContactPage('')}>0</p>
            </div>{" "}
          </div>
        </div>
        <div className="grid-items">
          <div className="grid-item-container">
            <div className="grid-two-inner">
              <p className="text-1">Unprocessed Emails</p>
              <p className="text-2" onClick={() => redirectToContactPage('')}>0</p>
            </div>{" "}
          </div>
        </div>
        <div className="grid-items">
          <div className="grid-item-container">
            <div className="grid-two-inner">
              <p className="text-1">Total Unsubscribes</p>
              <p className="text-2" onClick={() => redirectToContactPage('')}>{convertNumberIntoUSFromat(dashData?.totalUnsubscribeCount)}</p>
            </div>{" "}
          </div>
        </div>
        <div className="grid-items">
          <div className="grid-item-container">
            <div className="grid-two-inner">
              <p className="text-1">Total Bad Emails</p>
              <p className="text-2" onClick={() => redirectToContactPage('')}>{convertNumberIntoUSFromat(dashData?.totalSpamCount)}</p>
            </div>{" "}
          </div>
        </div>
        <div className="grid-items">
          <div className="grid-item-container">
            <div className="grid-two-inner">
              <p className="text-1">Incoming API updates</p>
              <p className="text-2" onClick={() => redirectToContactPage('')}>0</p>
            </div>{" "}
          </div>
        </div>
        <div className="grid-items">
          <div className="grid-item-container">
            <div className="grid-two-inner">
              <p className="text-1">Outgoing API Updates</p>
              <p className="text-2" onClick={() => redirectToContactPage('')}>0</p>
            </div>{" "}
          </div>
        </div>
      </div>

      {/* <div
        style={{
          marginBottom: "2rem",
          // height: "200px",
          width: "100%",
          backgroundColor: "white",
          marginTop: "4rem",
          borderRadius: "10px 10px 0px 0px",
          overflow: "hidden",
          border: "1px solid rgba(229, 231, 235, 1)",
        }}
      >
        <div>
          <p
            style={{
              padding: "0.9em 1.7em",
              backgroundColor: "rgba(250, 250, 250, 1)",
              borderBottom: "1px solid rgba(229, 231, 235, 1)",
              fontSize: "1.4em",
              fontWeight: "500",
            }}
          >
            Sendable
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "center",
              padding: "2.5em 3.5em",
            }}
          >
            <p className="p">Sendable Contacts</p>
            <p
              style={{
                marginLeft: "6rem",
                fontSize: "1.4em",
                fontWeight: "500",
              }}
            >
              10
            </p>
          </div>
        </div>
      </div> */}

      {/* <div
        style={{
          // height: "200px",
          width: "100%",
          backgroundColor: "white",
          marginTop: "4rem",
          borderRadius: "10px 10px 0px 0px",
          overflow: "hidden",
          border: "1px solid rgba(229, 231, 235, 1)",
        }}
      >
        <div>
          <p
            style={{
              padding: "0.9em 1.7em",
              backgroundColor: "rgba(250, 250, 250, 1)",
              borderBottom: "1px solid rgba(229, 231, 235, 1)",
              fontSize: "1.4em",
              fontWeight: "500",
            }}
          >
            Unsendable Emails
          </p>
        </div>
        <div className="unsendable-child">
          <div className="unsendable-child-element">
            <div
              className="child"
              style={{
                borderBottom: "1px solid rgba(229, 231, 235, 1) ",
                borderRight: "1px solid rgba(229, 231, 235, 1)",
              }}
            >
              <p className="p">Unprocessed Email</p>
              <h1>10</h1>
            </div>
            <div
              className="child"
              style={{ borderRight: "1px solid rgba(229, 231, 235, 1)" }}
            >
              <p className="p">Spam</p>
              <h1>10</h1>
            </div>
          </div>

          <div className="unsendable-child-element">
            <div
              className="child"
              style={{
                borderBottom: "1px solid rgba(229, 231, 235, 1) ",
                borderRight: "1px solid rgba(229, 231, 235, 1)",
              }}
            >
              <p className="p">Unsubscribed</p>
              <h1>10</h1>
            </div>
            <div
              className="child"
              style={{ borderRight: "1px solid rgba(229, 231, 235, 1)" }}
            >
              <p className="p">Hard Bounce</p>
              <h1>10</h1>
            </div>
          </div>

          <div className="unsendable-child-element">
            <div
              className="child"
              style={{ borderBottom: "1px solid rgba(229, 231, 235, 1) " }}
            >
              <p className="p">Blocked / Blacklist</p>
              <h1>10</h1>
            </div>
            <div className="child">
              <p className="p">Soft Bounce</p>
              <h1>10</h1>
            </div>
          </div>
        </div>
      </div> */}
      <div style={{ height: "5vh" }}></div>
    </div>
  );
};

export default Dashboard;
